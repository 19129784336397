import {
  useEffect, useRef, useState
} from 'react'
import { Popup } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Center,
  Grid,
  GridItem,
  IconButton,
  Image,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ArrowIcon } from 'assets/icons'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { StyledButtonContainer } from 'common/Buttons/SingleButton/styled'
import { LoadingSpinier } from 'common/Loading'
import {
  addFriend,
  deleteFriend,
  getNotificationMessage,
  getPosts,
  usersSelectors
} from 'store'
import { openConnectedProfileChat, setForceCloseChatPanel } from 'store/chat/slice'
import {
  IS_FRIEND_STATUSES,
  SUCCEEDED,
  REJECTED
} from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'
import { translateFromLocalUrl, statusMarkerColor } from 'utils/helpers'
import { BlogList } from 'components/Notifications/CommunityBlogs/BlogList'
import { Comments } from 'components/Notifications/CommunityBlogs/Comments'
import {
  StyledFlexContainer,
  StyledModalContainer,
  StyledUserProfileContainer,
  StyledWordsContent,
  StyledWordsTitle
} from './styled'

const ctaButtonTextObj = {
  connect: 'Connect',
  contact: 'Contact',
  pending: 'Pending',
  delete: 'Delete'
}

export const MarkerPopup = ({
  selectedMarkerData,
  markerPopupCoords
}) => {
  const dispatch = useDispatch()

  const popupRef = useRef(null)

  const {
    activeProfile,
    blogParent,
    comments,
    commentsOption,
    getCommentsStatus,
    getPostsStatus,
    postCommentsStatus,
    putCommentsStatus,
    posts,
    profileItem
  } = useSelector(usersSelectors)

  const [blogSection, setBlogSection] = useState('posts')
  const dataLink = translateFromLocalUrl()
  const [isCopiedLink, setIsCopiedLink] = useState(false)
  const [copyTextWarning, setCopyTextWarning] = useState('Share')
  const [pay, setPay] = useState('Pay')
  const [ctaButtonText, setCtaButtonText] = useState(ctaButtonTextObj.connect)
  const [isAdd, setIsAdd] = useState(true)

  const validDate = dayjs(selectedMarkerData?.last_accessed)
  const iconMarker = statusMarkerColor(validDate)

  const handleCtaClick = () => {
    if (profileItem.is_friends === IS_FRIEND_STATUSES.accepted) {
      dispatch(openConnectedProfileChat(profileItem))
    } else if (profileItem.is_friends === IS_FRIEND_STATUSES.no) {
      if (activeProfile?.id) {
        setIsAdd(false)
        setTimeout(() => dispatch(addFriend(profileItem?.id)), 500)
        setTimeout(() => {
          setIsAdd(true)
          setCtaButtonText(ctaButtonTextObj.pending)
        }, 2500)
      } else {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'You do not have an active profile. Please setup your profile first.'
          })
        )
      }
    }
  }

  // To be hidden until further instructions
  const handleRemoveConnection = () => {
    // todo move this fucntionality for delete
    setIsAdd(false)
    setTimeout(() => dispatch(deleteFriend(profileItem?.id)), 1000)
    setTimeout(() => setIsAdd(true), 3000)
    // close Chat pahhel if it is opened
    // to prevent user sending messages after unfriending
    dispatch(setForceCloseChatPanel(true))
  }

  const handlePay = () => {
    setPay('Coming Soon')
    setTimeout(() => setPay('Pay'), 2000)
  }

  const handleShare = () => {
    setIsCopiedLink(true)
    setCopyTextWarning('COPIED')
    navigator.clipboard.writeText(translateFromLocalUrl())
    dispatch(
      getNotificationMessage({
        type: SUCCEEDED,
        message: 'Copied'
      })
    )
  }

  const getMinheight = () => {
    let height = ''
    if (blogSection === 'posts') {
      height = posts.length ? '500px' : ''
    } else if (blogSection === 'comments') {
      height = comments.length ? '500px' : '380px'
    }

    return height
  }

  useEffect(() => {
    if (isCopiedLink) {
      const clearSuccess = setTimeout(() => {
        setCopyTextWarning('Share')
        setIsCopiedLink(false)
      }, 1000)

      return () => {
        clearTimeout(clearSuccess)
      }
    }
    return undefined
  }, [isCopiedLink])

  useEffect(() => {
    if (profileItem?.is_friends === IS_FRIEND_STATUSES.accepted) {
      setCtaButtonText(ctaButtonTextObj.contact)
    } else if (profileItem?.is_friends === IS_FRIEND_STATUSES.pending) {
      setCtaButtonText(ctaButtonTextObj.pending)
    }

    if (profileItem !== null) {
      dispatch(getPosts({
        type: 'marker',
        id: selectedMarkerData.id
      }))
    }
  }, [profileItem])

  return (
    <Popup
      maxWidth={350}
      minWidth={300}
      closeButton={false}
      closeOnClick={isAdd}
      offset={[0, -10]}
      ref={popupRef}
      position={markerPopupCoords}
      autoClose
    >
      {profileItem === null ? (
        <Box width="100%" height="170px">
          <LoadingSpinier />
        </Box>
      ) : (
        <StyledModalContainer>
          <StyledUserProfileContainer>
            <Box
              position="absolute"
              width="100%"
              height="95px"
              zIndex={1}
              backgroundImage={profileItem?.banner || ImagesPng?.DefaultImage}
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="cover"
              borderTopRadius={10}
              left={0}
              top={0}
              paddingX={10}
            />
            <Grid
              width="100%"
              marginX={5}
              marginY={2}
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(6, 1fr)"
              zIndex={2}
              gap={2.5}
            >
              <GridItem colSpan={2} rowSpan={2} width="86px">
                <Center>
                  <Image
                    boxSize="70px"
                    objectFit="cover"
                    src={profileItem?.profile_picture || ImagesPng.DefaultImage}
                    alt="image"
                    borderRadius="full"
                  />
                </Center>
              </GridItem>
              <GridItem colSpan={4} rowSpan={1}>
                {profileItem?.is_my_profile ? null : (
                  <Stack
                    direction="row"
                    position="relative"
                    marginLeft={profileItem?.is_friends === IS_FRIEND_STATUSES.accepted ? '0px' : '29%'}
                    height="50px"
                    sx={{
                      '@media (max-width: 480px)': {
                        marginLeft: profileItem?.is_friends === IS_FRIEND_STATUSES.accepted ? '10px' : '40%'
                      }
                    }}
                  >
                    <Stack direction="row" height="30px">
                      <SingleButton
                        text={copyTextWarning}
                        dataLink={dataLink}
                        click={handleShare}
                        name="share"
                        className="cardButton share"
                        customStyled={{
                          width: '65px'
                        }}
                      />
                      <SingleButton
                        click={handleCtaClick}
                        name="connect-status"
                        text={ctaButtonText}
                        className="cardButton"
                      />
                      {
                        profileItem?.is_friends === IS_FRIEND_STATUSES.accepted
                          ? (
                            <SingleButton
                              className="cardButton"
                              customStyled={{
                                backgroundColor: COLORS.gray
                              }}
                              click={handleRemoveConnection}
                              text={ctaButtonTextObj.delete}
                              name="delete-connect"
                            />
                          )
                          : null
                      }
                    </Stack>
                  </Stack>
                )}
              </GridItem>
              <GridItem rowSpan={1} colSpan={4}>
                <Stack direction="column" spacing="4px">
                  <Stack alignItems="center" direction="row" spacing="10px">
                    <Text
                      fontSize="16px"
                      backgroundColor="white"
                      p="5px"
                      position="absolute"
                      borderRadius={3}
                      width="200px"
                      data-name="profileCardName"
                      sx={{
                        '@media (max-width: 480px)': {
                          width: '170px',
                          'word-break': 'break-all'
                        }
                      }}
                    >
                      {profileItem?.full_name}
                    </Text>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </StyledUserProfileContainer>

          {
            profileItem?.personal_quote ? (
              <Stack direction="column" fontSize={15} marginX={20}>
                <Text
                  as="span"
                  align="center"
                  sx={{
                    '::-webkit-line-clamp': '3',
                    'line-clamp': '3'
                  }}
                >
                  {profileItem?.personal_quote}
                </Text>
              </Stack>
            ) : ''
          }

          <Stack textAlign="center" direction="column" justifyContent="space-between" marginX={10} fontSize={15}>
            <Stack justifyContent="space-between" direction="row">
              <Text as="span" fontWeight="bold">Connections</Text>
              <Text as="span">
                {profileItem?.active_connections}
              </Text>
            </Stack>
            <Stack justifyContent="space-between" direction="row">
              <Text as="span" fontWeight="bold">Disconnections</Text>
              <Text as="span">
                {profileItem?.past_connections}
                %
              </Text>
            </Stack>
            <Stack justifyContent="space-between" direction="row">
              <Text as="span" fontWeight="bold">Status</Text>
              <Text as="span">
                {iconMarker}
              </Text>
            </Stack>
          </Stack>

          <StyledWordsTitle>Affinities</StyledWordsTitle>

          <StyledFlexContainer>
            {profileItem?.affinities?.map((aff) => (
              <StyledWordsContent key={uuid()}>{aff.title}</StyledWordsContent>
            ))}
          </StyledFlexContainer>

          {profileItem?.social_links ? (
            <>
              <StyledWordsTitle>Social Profiles</StyledWordsTitle>

              <Stack direction="column" marginX={5}>
                <Stack direction="column">
                  {profileItem.social_links?.length > 0 ? (
                    <Stack direction="row" spacing="10px">
                      <Stack gap="12px" spacing="unset" flexWrap="wrap" direction="row">
                        {profileItem.social_links.map((item) => (
                          <Box
                            key={uuid()}
                            borderRadius="full"
                            bg={COLORS.goldGradient}
                            p="6px 10px"
                          >
                            <Link href={item.link} target="_blank" name={item.name} _cursor="pointer" style={{ textDecoration: 'none', color: 'black' }}>
                              {item.name}
                            </Link>
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  ) : (
                    <Text as="span" color={COLORS.gray}>No data available</Text>
                  )}
                </Stack>
              </Stack>
            </>
          ) : null}

          <Stack
            width="auto"
            direction="row"
          >
            <StyledWordsTitle>Blogs</StyledWordsTitle>

            <IconButton
              name="profile-pane-back-btn"
              _hover={{ background: COLORS.gold_hover }}
              background={COLORS.gold}
              width="auto"
              height="auto"
              display={blogSection === 'posts' ? 'none' : 'flex'}
              icon={<ArrowIcon width="15" height="15" />}
              onClick={() => setBlogSection('posts')}
              outline="none"
              boxShadow="none"
              marginLeft="-5%"
              css={{
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none'
                }
              }}
            />
          </Stack>

          <Stack
            margin={posts.length ? '0 5%' : '-5% 5%'}
            height="auto"
            // Transform is only used to force reflow on Safari
            transform="translateZ(0)"
            overflowY={
              (
                posts.length
                && blogSection === 'posts'
              )
              || (
                blogSection === 'comments'
              )
                ? 'scroll'
                : 'unset'
            }
            minHeight={getMinheight}
            sx={{
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            <Stack
              display={blogSection === 'posts' ? 'block' : 'none'}
            >
              <BlogList
                getPostsStatus={getPostsStatus}
                posts={posts}
                postsOption={{
                  type: 'marker'
                }}
                profileItem={profileItem}
                setBlogSection={setBlogSection}
              />
            </Stack>
            {
              blogSection === 'comments'
                ? (
                  <Comments
                    activeProfile={activeProfile}
                    blogParent={blogParent}
                    comments={comments}
                    commentsOption={commentsOption}
                    getCommentsStatus={getCommentsStatus}
                    postCommentsStatus={postCommentsStatus}
                    putCommentsStatus={putCommentsStatus}
                    postsOption={{
                      type: 'marker'
                    }}
                  />
                ) : ''
            }
          </Stack>

          <StyledButtonContainer
            onClick={() => handlePay()}
            name="pay"
            style={{
              margin: '30px 20px 10px',
              padding: '7.5px 0px',
              fontWeight: '500',
              background: COLORS.goldGradient
            }}
          >
            {pay}
          </StyledButtonContainer>
        </StyledModalContainer>
      )}
    </Popup>
  )
}
