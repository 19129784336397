import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Box,
  Image,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  getComments,
  getPosts,
  likePost,
  setBlogParent,
  setComments,
  setNotificationSectionPage
} from 'store'
import {
  LikeIcon,
  CommentIcon,
  RepostIcon,
  ShareIcon
} from 'assets/icons'
import { LoadingSpinier } from 'common/Loading'
import {
  NotificationSections,
  PENDING
} from 'utils/constants'
import { formattedDate } from 'utils/helpers'
import { TextEditorReader } from 'components/UserSection/ProfileBlog/BlogList/TextEditorReader'
import { LikeButtonAnimation } from './styled'

export const BlogList = ({
  getPostsStatus,
  posts,
  postsOption,
  profileItem,
  setBlogSection // Only used on marker popup
}) => {
  const dispatch = useDispatch()
  const [hideText, setHideText] = useState([])
  const [animate, setAnimate] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [oldPostsCount, setOldPostsCount] = useState(0)
  const observer = useRef()
  const secondToLastItem = useRef(null)

  const hideTextMap = (id) => {
    setHideText((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  const animateLike = (id) => {
    setAnimate((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))

    setTimeout(() => {
      setAnimate((prevState) => ({
        ...prevState,
        [id]: !prevState[id]
      }))
    }, 500)
  }

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      // The "10" is the limit set on the backend DB query
      if (entries[0].isIntersecting && posts.length - oldPostsCount >= 10) {
        observer.current.unobserve(secondToLastItem.current)
        setLoadingMore(true)
        setOldPostsCount(posts.length)
        dispatch(getPosts({
          type: postsOption.type,
          ...(postsOption.type === 'affinity' ? { id: postsOption.id } : {}),
          ...(postsOption.type === 'marker' ? { id: profileItem.id } : {}),
          lastItemId: posts.at(-1).id
        }))
      }
    })

    const currentObserver = observer.current
    if (secondToLastItem.current && posts.length - oldPostsCount >= 10) {
      currentObserver.observe(secondToLastItem.current)
    }

    setLoadingMore(false)

    return () => {
      if (secondToLastItem.current) {
        observer.current.disconnect()
      }
    }
  }, [posts])

  return (
    <Stack margin="2% 0">
      {
        getPostsStatus === PENDING && !posts.length
          ? (
            <Stack marginTop="100px">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
      {
        posts && posts.length
          ? posts.map((item, index) => {
            let profilePicture = ''
            let fullName = ''

            if (profileItem) {
              profilePicture = profileItem.profile_picture ?? ImagesPng.DefaultImage
              fullName = profileItem.full_name ?? ImagesPng.DefaultImage
            } else {
              profilePicture = item.profile_picture ?? ImagesPng.DefaultImage
              fullName = item.full_name ?? ImagesPng.DefaultImage
            }

            return (
              <Stack
                ref={posts.length - 2 === index ? secondToLastItem : null}
                backgroundColor="rgb(229, 229, 229)"
                width="96%"
                borderRadius="3px"
                border="solid 1px black"
                padding="10px"
                display="flex"
                justifyContent="flex-start"
                margin="0 2%"
              >
                <Stack
                  alignItems="center"
                  display="inline"
                  width="auto"
                  justifyContent="flex-start"
                  marginLeft="30px"
                >
                  <Image
                    boxSize="55px"
                    position="relative"
                    objectFit="cover"
                    src={profilePicture}
                    alt="User Image"
                    borderRadius="full"
                    display="inline-flex"
                    verticalAlign="bottom"
                  />
                  <Text
                    display="inline-flex"
                    marginLeft="10px"
                    fontSize="16px"
                    lineHeight="28px"
                    wordBreak="break-word"
                    margin="10px 0 0 10px !important"
                  >
                    {fullName}
                    <br />
                    {formattedDate(item.created_at)}
                  </Text>
                </Stack>

                <Stack
                  display="flex"
                  justifyContent="center"
                  direction="row"
                >
                  <Button
                    background={
                      hideText[item.id]
                        ? COLORS.darkGrayGradientBotToTop
                        : COLORS.lightGoldGradientLeftToRight
                    }
                    border="solid 1px rgb(119, 119, 119)"
                    padding="2px 0"
                    fontSize="19px"
                    width="95%"
                    borderRadius="5px"
                    align="center"
                    letterSpacing=".15px"
                    fontWeight="400"
                    _hover={{
                      background: ''
                    }}
                    _active={{
                      background: ''
                    }}
                    onClick={() => hideTextMap(item.id)}
                  >
                    {item.title}
                  </Button>
                </Stack>

                <Stack
                  direction="row"
                  padding="2.5px 0px"
                  justifyContent="center"
                  margin="-2.5px 0"
                  display={hideText[item.id] ? 'none' : 'flex'}
                >
                  {
                    item.affinities.length
                      ? item.affinities.map((e) => (
                        <Box
                          color="#F8F8FF"
                          border="solid 1px black"
                          borderRadius="5px"
                          bg={COLORS.gold}
                          p="0 2px"
                          data-name="blog-affinity"
                          fontSize="11px"
                        >
                          {e.title}
                        </Box>
                      ))
                      : (
                        <Box
                          color="#F8F8FF"
                          border="solid 1px black"
                          borderRadius="5px"
                          bg={COLORS.gold}
                          p="0 2px"
                          data-name="blog-affinity"
                          fontSize="11px"
                        >
                          No Affinities
                        </Box>
                      )
                  }
                </Stack>

                <Stack display={hideText[item.id] ? 'none' : 'block'}>
                  <TextEditorReader item={item} />
                </Stack>

                <Stack
                  direction="row"
                  borderTop="solid 1px black"
                  width="calc(100% + 20px)"
                  padding={postsOption.type === 'marker' ? '0' : '3.5px 0px'}
                  justifyContent="space-around"
                  marginBottom="-10px"
                  marginLeft="-10px"
                >
                  <LikeButtonAnimation className={animate[item.id] ? 'animate' : ''}>
                    <Stack
                      onClick={() => {
                        animateLike(item.id)
                        dispatch(likePost(item.id))
                      }}
                    >
                      <LikeIcon fill={item.likedByMe ? COLORS.gold : 'none'} />
                    </Stack>
                    <Text>{item.likes}</Text>
                  </LikeButtonAnimation>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Stack
                      onClick={() => {
                        dispatch(setComments([]))
                        dispatch(setBlogParent([item]))
                        dispatch(getComments({
                          type: 'post',
                          post_id: item.id
                        }))
                        dispatch(setNotificationSectionPage(
                          NotificationSections.BLOG_COMMENTS_FOR_POST
                        ))

                        if (postsOption.type === 'marker') {
                          setBlogSection('comments')
                        }
                      }}
                    >
                      <CommentIcon />
                    </Stack>
                    <Text>{item.comments}</Text>
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <RepostIcon />
                    <Text>0</Text>
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <ShareIcon />
                    <Text>0</Text>
                  </Stack>
                </Stack>
              </Stack>
            )
          })
          : (
            <Stack>
              {
                getPostsStatus === PENDING && !posts.length
                  ? ''
                  : (
                    <Text
                      textAlign="center"
                      backgroundColor="rgb(229, 229, 229)"
                      borderRadius="3px"
                      border="solid 1px black"
                      padding="10px"
                      margin="0 2%"
                    >
                      There are no posts for this community
                    </Text>
                  )
              }
            </Stack>
          )
      }
      {
        loadingMore && getPostsStatus === PENDING
          ? (
            <Stack margin="75px 0 60px 0">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
    </Stack>
  )
}
