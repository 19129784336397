import { createSlice, current } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { getMarkers } from 'store'
import { PENDING, REJECTED, SUCCEEDED } from 'utils/constants'
import { getFriendMarkers, liveMarkerUpdate, staticMarkerUpdate } from './axios'

const initialState = {
  markers: [],
  friendMarkers: [],
  liveMarkerExp: null,
  staticMarkerExp: null,
  northeast: [],
  southwest: [],
  isFiltered: false,
  filters: [],
  storeId: '',
  isLocationMyPosition: false,
  openedMarkerCoordinate: null,
  getTestStatus: PENDING | SUCCEEDED | REJECTED,
  getFriendMarkerStatus: PENDING | SUCCEEDED | REJECTED,
  liveMarkerUpdateStatus: PENDING | SUCCEEDED | REJECTED,
  staticMarkerUpdateStatus: PENDING | SUCCEEDED | REJECTED,
  currentChatFriendMarkerCoordinate: null,
  markerUpdate: true,
  selectedMarkerData: null
}

const markers = createSlice({
  name: 'markers',
  initialState,
  reducers: {
    handleLocationMyPosition: (state) => {
      state.isLocationMyPosition = !state.isLocationMyPosition
    },
    showCurrentChatFriendMarkerCoordinate: (state, { payload }) => {
      state.currentChatFriendMarkerCoordinate = payload
    },
    // cannot be used due to bugs
    getIdfromParam: (state, { payload }) => {
      state.storeId = payload
    },
    clearId: (state) => {
      state.storeId = ''
    },
    setMarkerUpdate: (state, { payload }) => {
      state.markerUpdate = payload
    },
    setBounds: (state, { payload }) => {
      state.northeast = payload?.northeast
      state.southwest = payload?.southwest
    },
    xmppUpdateFriendLocation: (state, { payload }) => {
      const friendLocation = payload
      // from : "501167-0@dat01.turnkeywebstores.biz"
      // fromNickname : "Live location enabled"
      // geo: { "lat": 60.2029599, "lng": 24.6540796 }
      const friendId = Number(friendLocation.from.split('-')[0])
      const newLocation = [friendLocation.geo.lat, friendLocation.geo.lng]
      if (Number.isNaN(friendId)) {
        return
      }
      const date = new Date()
      const dateStr = date.toISOString().replace('T', ' ').slice(0, 19)
      state.markers.forEach((e) => {
        if (e.id === friendId) {
          e.coordinates = newLocation
          e.last_accessed = dateStr
          e.isLive = true
        }
      })
    },
    filterMarkers: (state, { payload }) => {
      state.filters = payload
      if (payload.affinities.length > 0
      || payload.codewords.length > 0) {
        state.isFiltered = true
      } else {
        state.isFiltered = false
      }
    },
    removeMarker: (state, action) => {
      console.log(action.payload)
      state.markers?.some((items, index) => {
        if (items?.id === action.payload) {
          console.log('remove')
          state.markers.splice(index, 1)
          return true
        }
        return false
      })
    },
    setSelectedMarkerData: (state, action) => {
      state.selectedMarkerData = action.payload
    }
  },
  extraReducers: {
    [getMarkers.pending]: (state) => {
      state.getTestStatus = PENDING
    },
    [getMarkers.fulfilled]: (state, action) => {
      state.getTestStatus = SUCCEEDED
      state.markers = action.payload?.data
      state.liveMarkerExp = action.payload?.liveMarkersExpiration
      state.staticMarkerExp = action.payload?.staticMarkersExpiration
    },
    [getMarkers.rejected]: (state) => {
      state.getTestStatus = REJECTED
    },
    // Get Friend Marker
    [getFriendMarkers.pending]: (state) => {
      state.getFriendMarkerStatus = PENDING
    },
    [getFriendMarkers.fulfilled]: (state, action) => {
      state.getFriendMarkerStatus = SUCCEEDED
      state.friendMarkers = action.payload
    },
    [getFriendMarkers.rejected]: (state) => {
      state.getFriendMarkerStatus = REJECTED
    },
    // Get Live Marker
    [liveMarkerUpdate.pending]: (state) => {
      state.liveMarkerUpdateStatus = PENDING
    },
    [liveMarkerUpdate.fulfilled]: (state, action) => {
      state.liveMarkerUpdateStatus = SUCCEEDED
      state.liveMarkerExp = action.payload?.expiration

      action.payload?.deletedProfiles.forEach((item) => {
        state.markers?.some((items, index) => {
          if (items?.id === item?.profile_id) {
            state.markers.splice(index, 1)
            return true
          }
          return false
        })
      })

      action.payload?.data.forEach((item) => {
        let found = false

        state.markers?.some((items) => {
          if (items?.id === item?.id) {
            items.coordinates = item?.coordinates
            found = true
            return true
          }
          return false
        })

        if (!found) {
          state.markers.push(item)
        }
      })
    },
    [liveMarkerUpdate.rejected]: (state) => {
      state.liveMarkerUpdateStatus = REJECTED
    },
    // Get Static Marker
    [staticMarkerUpdate.pending]: (state) => {
      state.staticMarkerUpdateStatus = PENDING
    },
    [staticMarkerUpdate.fulfilled]: (state, action) => {
      state.staticMarkerUpdateStatus = SUCCEEDED
      state.staticMarkerExp = action.payload?.expiration

      action.payload?.deletedProfiles.forEach((item) => {
        state.markers?.some((items, index) => {
          if (items?.id === item?.profile_id) {
            state.markers.splice(index, 1)
            return true
          }
          return false
        })
      })

      action.payload?.data.forEach((item) => {
        let found = false

        state.markers?.some((items) => {
          if (items?.id === item?.id) {
            items.coordinates = item?.coordinates
            found = true
            return true
          }
          return false
        })

        if (!found) {
          state.markers.push(item)
        }
      })
    },
    [staticMarkerUpdate.rejected]: (state) => {
      state.staticMarkerUpdateStatus = REJECTED
    }
  }
})

export const {
  handleLocationMyPosition,
  showCurrentChatFriendMarkerCoordinate,
  getIdfromParam,
  clearId,
  setMarkerUpdate,
  setBounds,
  xmppUpdateFriendLocation,
  filterMarkers,
  removeMarker,
  setSelectedMarkerData
} = markers.actions

export default markers.reducer
